// src/App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Prospectos from './components/Prospectos';
import Clientes from './components/Clientes';
import CrearPedido from './components/CrearPedido';
import Productos from './components/Productos';
import Pedido from './components/Pedido';
import BottomNavigation from './components/BottomNavigation';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider, useAuth } from './hooks/useAuth';
import TopBar from './components/TopBar';

const AppContent = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="content">
      <TopBar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/prospectos"
          element={
            <PrivateRoute>
              <Prospectos />
            </PrivateRoute>
          }
        />
        <Route
          path="/clientes"
          element={
            <PrivateRoute>
              <Clientes />
            </PrivateRoute>
          }
        />
        <Route
          path="/crear-pedido/:clientId"
          element={
            <PrivateRoute>
              <CrearPedido />
            </PrivateRoute>
          }
        />
        <Route
          path="/productos"
          element={
            <PrivateRoute>
              <Productos />
            </PrivateRoute>
          }
        />
        <Route
          path="/pedido"
          element={
            <PrivateRoute>
              <Pedido />
            </PrivateRoute>
          }
        />
      </Routes>
      {user && <BottomNavigation />}
    </div>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

export default App;
