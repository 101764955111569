// src/components/Clientes.js
import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase'; // Asegúrate de importar auth desde firebase.js
import { FaWhatsapp, FaShoppingCart } from 'react-icons/fa';

const Clientes = () => {
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Escuchar los cambios de autenticación
    const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    if (currentUser) {
      const q = query(collection(db, 'clients'), where('agent', '==', currentUser.uid));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const clientsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setClients(clientsData);
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  const sendMessage = (client) => {
    const phone = client.phone.replace(/\D/g, ''); // Eliminar todos los caracteres no numéricos
    const whatsappURL = `https://wa.me/52${phone}`;
    window.open(whatsappURL, '_blank');
  };

  const handleCreateOrder = (clientId) => {
    // Redirigir a la nueva vista para crear un pedido
    navigate(`/crear-pedido/${clientId}`);
  };

  const filteredClients = clients.filter(client =>
    client.businessName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4">Clientes</h1>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Buscar clientes"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b border-gray-200">Nombre del Negocio</th>
              <th className="py-2 px-4 border-b border-gray-200">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {filteredClients.map(client => (
              <tr key={client.id}>
                <td className="py-2 px-4 border-b border-gray-200">{client.businessName}</td>
                <td className="py-2 px-4 border-b border-gray-200">
                  <div className="flex space-x-2">
                    <button
                      className="text-green-500 hover:text-green-700"
                      onClick={() => sendMessage(client)}
                    >
                      <FaWhatsapp size={20} />
                    </button>
                    <button
                      className="text-blue-500 hover:text-blue-700"
                      onClick={() => handleCreateOrder(client.id)}
                    >
                      <FaShoppingCart size={20} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Clientes;
