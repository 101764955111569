import React, { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import ReactPlayer from 'react-player';
import Modal from 'react-modal';
import imgVideo from '../assets/imgVideo.png';

Modal.setAppElement('#root');

const Dashboard = () => {
  const { user } = useAuth();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <div className="container mt-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
      <h1 style={{ fontSize: '40px', textAlign: 'center', position: 'absolute', top: '10%', width: '100%' }}>
        Bienvenido, {user?.nombre} {user?.apellido}
      </h1>
      
      {/* Imagen que actúa como botón para abrir el modal */}
      <img
        src={imgVideo}
        alt="Play Video"
        onClick={openModal}
        style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '100%' }}
      />

      {/* Modal que se abrirá en pantalla completa para reproducir el video */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        style={{
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '90%', // Ajusta el tamaño según necesites
            height: '90%' // Ajusta el tamaño según necesites
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 1000 // Asegura que el modal está sobre otros componentes
          }
        }}
      >
        <ReactPlayer
          url="https://vimeo.com/993672426"
          playing
          controls
          width="100%"
          height="100%"
        />
        <button onClick={closeModal} style={{ position: 'absolute', top: '10px', right: '10px', fontSize: '16px', padding: '5px' }}>Cerrar</button>
      </Modal>
    </div>
  );
};

export default Dashboard;
