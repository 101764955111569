// src/components/TopBar.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FaBars, FaSignOutAlt } from 'react-icons/fa'; // Importamos el icono para cerrar sesión
import { useAuth } from '../hooks/useAuth'; // Importamos el hook de autenticación
import './TopBar.css';
import logo from '../assets/logo.png'; // Asegúrate de tener el logo en esta ruta

const TopBar = () => {
  const location = useLocation();
  const [title, setTitle] = useState('');
  const [showTitle, setShowTitle] = useState(false);
  const { user, logout } = useAuth(); // Obtenemos la función de cerrar sesión del hook de autenticación

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setTitle('Dashboard');
        break;
      case '/prospectos':
        setTitle('Prospectos');
        break;
      case '/clientes':
        setTitle('Clientes');
        break;
      case '/pedido':
        setTitle('Pedido');
        break;
      case '/productos':
        setTitle('Productos');
        break;
      default:
        setTitle('');
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowTitle(true);
      } else {
        setShowTitle(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleLogout = async () => {
    await logout();
    window.location.href = '/login';
  };

  // Ocultar la barra superior en la página de inicio de sesión
  if (location.pathname === '/login') {
    return null;
  }

  return (
    <div className="top-bar fixed-top d-flex align-items-center justify-content-between px-3">
      <img src={logo} alt="Logo" className="logo" />
      {showTitle && <span className="page-title ml-3">{title}</span>}
      <div className="d-flex align-items-center">
        <FaBars className="menu-icon mr-3" />
        <FaSignOutAlt className="menu-icon" onClick={handleLogout} />
      </div>
    </div>
  );
};

export default TopBar;
