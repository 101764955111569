import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, onSnapshot } from 'firebase/firestore';
import ProductDrawer from './ProductDrawer';
import noLogo from '../assets/no-logo-xico.png'; // Importa la imagen de respaldo
import 'tailwindcss/tailwind.css';

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const unsubscribeProducts = onSnapshot(collection(db, 'products'), (snapshot) => {
      const productsList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(productsList);
      setFilteredProducts(productsList);
    });

    const unsubscribeCategories = onSnapshot(collection(db, 'categories'), (snapshot) => {
      const categoriesList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCategories(categoriesList);
    });

    const unsubscribeBrands = onSnapshot(collection(db, 'brands'), (snapshot) => {
      const brandsList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setBrands(brandsList);
    });

    return () => {
      unsubscribeProducts();
      unsubscribeCategories();
      unsubscribeBrands();
    };
  }, []);

  useEffect(() => {
    const filtered = products.filter(product =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (selectedCategory ? product.category === selectedCategory : true) &&
      (selectedBrand ? product.brand === selectedBrand : true)
    );
    setFilteredProducts(filtered);
  }, [searchQuery, selectedCategory, selectedBrand, products]);

  const calculateDiscountedPrice = (price, discounts) => {
    let discountedPrice = parseFloat(price);
    discounts.forEach(discount => {
      discountedPrice -= (discountedPrice * parseFloat(discount.percentage)) / 100;
    });
    return discountedPrice.toFixed(2);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleBrandChange = (e) => {
    setSelectedBrand(e.target.value);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl">Productos</h1>
        <input
          type="text"
          placeholder="Buscar..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="flex justify-between items-center mb-4">
        <select
          value={selectedCategory}
          onChange={handleCategoryChange}
          className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 mr-4"
        >
          <option value="">Todas las Categorías</option>
          {categories.map(category => (
            <option key={category.id} value={category.id}>{category.name}</option>
          ))}
        </select>
        <select
          value={selectedBrand}
          onChange={handleBrandChange}
          className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Todas las Marcas</option>
          {brands.map(brand => (
            <option key={brand.id} value={brand.id}>{brand.name}</option>
          ))}
        </select>
      </div>
      <div className="grid grid-cols-2 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {filteredProducts.map(product => {
          const discountedPrice = product.discounts ? calculateDiscountedPrice(product.price, product.discounts) : product.price;
          return (
            <div
              key={product.id}
              className="bg-white p-4 rounded-lg shadow-md cursor-pointer"
              onClick={() => setSelectedProduct(product)}
            >
              <img
                src={product.imageUrl || noLogo} // Usa la imagen de respaldo si no hay imageUrl
                alt={product.name}
                className="w-full h-40 object-cover mb-4 rounded"
                onError={(e) => { e.target.onerror = null; e.target.src = noLogo; }} // Manejo de error en caso de falla de la imagen
              />
              <h2 className="text-lg font-bold mb-2">{product.name}</h2>
              <p className="text-gray-700 mb-2">{product.description}</p>
              {product.discounts && product.discounts.length > 0 ? (
                <div>
                  <p className="text-red-500 font-semibold line-through">${parseFloat(product.price).toFixed(2)}</p>
                  <p className="text-green-500 font-semibold">${discountedPrice}</p>
                </div>
              ) : (
                <p className="text-blue-500 font-semibold">${parseFloat(product.price).toFixed(2)}</p>
              )}
            </div>
          );
        })}
      </div>
      <ProductDrawer product={selectedProduct} closeDrawer={() => setSelectedProduct(null)} />
    </div>
  );
};

export default ProductsPage;
