import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, doc, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { FaWhatsapp, FaPlusCircle } from 'react-icons/fa';
import { DndProvider, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAuth } from '../hooks/useAuth';
import ProspectDrawer from './ProspectDrawer';

const ItemTypes = {
  PROSPECT: 'prospect',
};

const Prospectos = () => {
  const { user } = useAuth();
  const [prospects, setProspects] = useState([]);
  const [selectedProspect, setSelectedProspect] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [tags, setTags] = useState({});

  useEffect(() => {
    const fetchTags = async () => {
      const tagsSnapshot = await getDocs(collection(db, 'tags'));
      const tagsData = {};
      tagsSnapshot.forEach(doc => {
        tagsData[doc.id] = doc.data();
      });
      setTags(tagsData);
    };

    fetchTags();

    const q = collection(db, 'prospects');
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        const prospect = { id: doc.id, ...doc.data() };
        if (prospect.agent === user.uid) {
          data.push(prospect);
        }
      });

      setProspects(data);
    });

    return () => {
      unsubscribe();
    };
  }, [user.uid]);

  const openDrawer = (prospect) => {
    setSelectedProspect(prospect);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedProspect(null);
  };

  const sendMessage = (prospect) => {
    const phone = prospect.phone.replace(/\D/g, '');
    const whatsappURL = `https://wa.me/52${phone}`;
    window.open(whatsappURL, '_blank');
  };

  const Prospect = ({ prospect }) => {
    const [, ref] = useDrag({
      type: ItemTypes.PROSPECT,
      item: { prospect }
    });

    return (
      <div
        ref={ref}
        className="bg-white p-4 mb-4 rounded-lg shadow-sm cursor-pointer"
        onClick={() => openDrawer(prospect)}
      >
        <div className="flex justify-between items-center">
          <div>
            <p className="font-medium">{prospect.businessName}</p>
            <p className="text-sm text-gray-500">{prospect.contactPerson}</p>
          </div>
          <div className="flex flex-col items-end space-y-2">
            {prospect.tag && tags[prospect.tag] && (
              <span
                className="text-white px-2 py-1 rounded-full text-xs font-semibold"
                style={{ backgroundColor: tags[prospect.tag].color }}
              >
                {tags[prospect.tag].name}
              </span>
            )}
            {prospect.zone && (
              <span
                className="text-gray-700 px-2 py-1 rounded-full text-xs font-semibold bg-gray-200"
              >
                {prospect.zone}
              </span>
            )}
            <FaWhatsapp
              className="text-green-500 cursor-pointer"
              size={24}
              onClick={(e) => {
                e.stopPropagation();
                sendMessage(prospect);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="container mx-auto p-4">
        <h1 className="text-2xl mb-4">Prospectos</h1>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
          onClick={() => setDrawerOpen(true)}
        >
          <FaPlusCircle className="mr-2 inline" /> Agregar Prospecto
        </button>
        <div className="space-y-4">
          {prospects.map((prospect) => (
            <Prospect key={prospect.id} prospect={prospect} />
          ))}
        </div>
      </div>
      <ProspectDrawer isOpen={isDrawerOpen} closeDrawer={closeDrawer} prospect={selectedProspect} tags={tags} />
    </DndProvider>
  );
};

export default Prospectos;
