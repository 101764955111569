import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../hooks/useAuth';
import { db } from '../firebase';
import PedidoDrawer from './PedidoDrawer';

const Pedido = () => {
  const { user } = useAuth();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('procesando');

  useEffect(() => {
    if (user) {
      const q = query(collection(db, 'pedidos'), where('userId', '==', user.uid));
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const orderPromises = querySnapshot.docs.map(async (docSnapshot) => {
          const orderData = docSnapshot.data();
          const clientRef = doc(db, 'clients', orderData.clientId);
          const clientDoc = await getDoc(clientRef);
          return {
            id: docSnapshot.id,
            ...orderData,
            clientName: clientDoc.exists() ? clientDoc.data().businessName : 'Negocio desconocido'
          };
        });
        const ordersWithClientNames = await Promise.all(orderPromises); // Asegura que todas las promesas se resuelvan antes de actualizar el estado
        setOrders(ordersWithClientNames);
        setLoading(false);
      });
      return () => unsubscribe(); // Cleanup subscription on unmount
    }
  }, [user]);

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'enviado': return 'bg-gray-300'; // Gris claro
      case 'en proceso': return 'bg-yellow-400'; // Amarillo
      case 'en ruta': return 'bg-blue-200'; // Azul celeste claro
      case 'entregado': return 'bg-green-500'; // Verde
      default: return 'bg-gray-200'; // Por defecto gris si no hay estado reconocido
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4">Mis Pedidos</h1>
      <div className="flex justify-center mb-4">
        <div className="bg-gray-300 p-px rounded-full">
          <div className="flex bg-white p-1 rounded-full">
            <button
              className={`px-4 py-2 rounded-l-full ${activeTab === 'procesando' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
              onClick={() => toggleTab('procesando')}
            >
              Procesando
            </button>
            <button
              className={`px-4 py-2 rounded-r-full ${activeTab === 'historial' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
              onClick={() => toggleTab('historial')}
            >
              Historial
            </button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4">
        {orders.filter(order => activeTab === 'historial' ? order.status === 'entregado' : order.status !== 'entregado').map(order => (
          <div key={order.id} className="bg-white p-4 shadow rounded flex justify-between items-center">
            <div>
              <p><strong>Cliente:</strong> {order.clientName}</p>
              <p><strong>Total:</strong> ${order.total}</p>
              <p><strong>Productos:</strong> {order.products.length}</p>
            </div>
            <div className="flex flex-col items-end">
              <button
                onClick={() => {
                  setSelectedOrder(order);
                  setIsDrawerOpen(true);
                }}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Más detalles
              </button>
              <span className={`px-3 py-1 rounded-full text-sm font-semibold mt-1 ${getStatusColor(order.status)}`}>
                {order.status}
              </span>
            </div>
          </div>
        ))}
      </div>
      {selectedOrder && (
        <PedidoDrawer isOpen={isDrawerOpen} closeDrawer={() => {
          setIsDrawerOpen(false);
          setSelectedOrder(null);
        }} order={selectedOrder} />
      )}
    </div>
  );
};

export default Pedido;
