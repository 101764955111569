import React, { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';

const ProspectDrawer = ({ isOpen, closeDrawer, prospect, tags }) => {
  const [isRegisterVisit, setIsRegisterVisit] = useState(false);
  const [visitDate, setVisitDate] = useState('');
  const [visitNotes, setVisitNotes] = useState('');
  const [selectedTag, setSelectedTag] = useState(prospect ? prospect.tag : '');

  if (!prospect) return null;

  const handleRegisterVisit = async (e) => {
    e.preventDefault();
    // Implementar lógica para registrar visita
  };

  const handleConvertToClient = async () => {
    // Implementar lógica para convertir en cliente
  };

  const handleTagChange = async (e) => {
    const newTag = e.target.value;
    setSelectedTag(newTag);
    const prospectRef = doc(db, 'prospects', prospect.id);
    await updateDoc(prospectRef, { tag: newTag });
  };

  const formatVisitDate = (date) => {
    return formatDistanceToNow(new Date(date), { addSuffix: true, locale: es });
  };

  return (
    <Offcanvas show={isOpen} onHide={closeDrawer} placement="end" style={{ zIndex: 1050 }}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Información del Cliente</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <button
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
          onClick={() => setIsRegisterVisit(!isRegisterVisit)}
        >
          {isRegisterVisit ? 'Cancelar' : 'Registrar Visita'}
        </button>
        {isRegisterVisit && (
          <form className="mt-4 space-y-4" onSubmit={handleRegisterVisit}>
            <div>
              <label className="block text-sm font-medium text-gray-700">Fecha y Hora de Visita</label>
              <input
                type="datetime-local"
                value={visitDate}
                onChange={(e) => setVisitDate(e.target.value)}
                className="mt-1 block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Notas</label>
              <textarea
                value={visitNotes}
                onChange={(e) => setVisitNotes(e.target.value)}
                className="mt-1 block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
            >
              Guardar Visita
            </button>
          </form>
        )}
        <button
          className="mt-4 bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
          onClick={handleConvertToClient}
        >
          Convertir en Cliente
        </button>
        <div className="mt-4">
          <p><strong>Nombre del Negocio:</strong> {prospect.businessName}</p>
          <p><strong>Persona de Contacto:</strong> {prospect.contactPerson}</p>
          <p><strong>Teléfono:</strong> {prospect.phone}</p>
          <p><strong>Dirección:</strong> {prospect.address}</p>
          <div className="mt-4">
            <h3 className="text-lg font-medium text-gray-900">Etiqueta</h3>
            <div className="mt-2">
              <select
                value={selectedTag}
                onChange={handleTagChange}
                className="p-2 border border-gray-300 rounded"
              >
                {Object.entries(tags).map(([id, tag]) => (
                  <option key={id} value={id}>
                    {tag.name}
                  </option>
                ))}
              </select>
            </div>
            {selectedTag && tags[selectedTag] && (
              <div className="mt-2">
                <span
                  className="text-white px-2 py-1 rounded-full text-xs font-semibold"
                  style={{ backgroundColor: tags[selectedTag].color }}
                >
                  {tags[selectedTag].name}
                </span>
              </div>
            )}
          </div>
          <div className="mt-4">
            <h3 className="text-lg font-medium text-gray-900">Zona</h3>
            {prospect.zone && (
              <div className="mt-2">
                <span
                  className="text-gray-700 px-2 py-1 rounded-full text-xs font-semibold bg-gray-200"
                >
                  {prospect.zone}
                </span>
              </div>
            )}
          </div>
          <div className="mt-4">
            <h3 className="text-lg font-medium text-gray-900">Historial de Visitas</h3>
            {prospect.visits && prospect.visits.length > 0 ? (
              <ul className="mt-2 space-y-2">
                {prospect.visits.map((visit, index) => (
                  <li key={index} className="flex space-x-3">
                    <div className="flex-shrink-0">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 5v14m7-7H5"
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">
                        {formatVisitDate(visit.date)} - {visit.notes}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-sm text-gray-500">Aún no ha sido visitado.</p>
            )}
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ProspectDrawer;
