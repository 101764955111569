import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, addDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import jsPDF from 'jspdf';
import { useAuth } from '../hooks/useAuth'; // Importamos el hook de autenticación

const CrearPedido = () => {
  const { clientId } = useParams();
  const { user } = useAuth(); // Obtenemos el usuario actual
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productQuantity, setProductQuantity] = useState(1);
  const [orderProducts, setOrderProducts] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState('');
  const [orderCreated, setOrderCreated] = useState(false);
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState('');

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const clientDoc = await getDoc(doc(db, 'clients', clientId));
        if (clientDoc.exists()) {
          setClient(clientDoc.data());
        } else {
          setError('No se encontró el cliente');
        }
      } catch (error) {
        setError('Error al obtener el cliente');
      } finally {
        setLoading(false);
      }
    };

    const fetchProducts = async () => {
      const productCollection = collection(db, 'products');
      const productSnapshot = await getDocs(productCollection);
      const productList = productSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(productList);
      setFilteredProducts(productList);
    };

    const fetchZones = async () => {
      const zonesCollection = collection(db, 'zonas');
      const zonesSnapshot = await getDocs(zonesCollection);
      const zonesList = zonesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setZones(zonesList);
    };

    fetchClient();
    fetchProducts();
    fetchZones();
  }, [clientId]);

  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    if (term.length > 0) {
      setFilteredProducts(products.filter(product => product.name.toLowerCase().includes(term)));
    } else {
      setFilteredProducts(products);
    }
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setSearchTerm('');
    setFilteredProducts(products);
  };

  const calculateDiscountedPrice = (price, discounts) => {
    let discountedPrice = parseFloat(price);
    discounts.forEach(discount => {
      discountedPrice -= (discountedPrice * parseFloat(discount.percentage)) / 100;
    });
    return discountedPrice.toFixed(2);
  };

  const handleAddProduct = () => {
    if (selectedProduct && productQuantity > 0) {
      const discountedPrice = calculateDiscountedPrice(selectedProduct.price, selectedProduct.discounts || []);
      setOrderProducts([...orderProducts, { id: selectedProduct.id, name: selectedProduct.name, imageUrl: selectedProduct.imageUrl, quantity: productQuantity, discountedPrice }]);
      setSelectedProduct(null);
      setProductQuantity(1);
    }
  };

  const totalQuantity = orderProducts.reduce((sum, product) => sum + product.quantity, 0);
  const totalPrice = orderProducts.reduce((sum, product) => sum + (product.discountedPrice * product.quantity), 0);

  const handleCreateOrder = async () => {
    if (!deliveryDate) {
      alert('Por favor, selecciona una fecha de entrega.');
      return;
    }

    if (!selectedZone) {
      alert('Por favor, selecciona una zona.');
      return;
    }

    try {
      const newOrder = {
        clientId,
        products: orderProducts.map(({ id, quantity }) => ({ id, quantity })),
        zona: selectedZone,
        status: 'enviado',
        total: totalPrice,
        totalQuantity,
        createdAt: new Date(),
        deliveryDate,
        userId: user.uid // Añadimos el userId del usuario actual
      };

      const docRef = await addDoc(collection(db, 'pedidos'), newOrder);

      // Enviar datos a Google Sheets mediante API de Node.js
      const response = await fetch('http://localhost:3001/add-order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientName: client.businessName,
          totalQuantity,
          totalPrice,
          deliveryDate,
          orderId: docRef.id
        }),
      });

      if (!response.ok) {
        throw new Error('Error al enviar datos a Google Sheets');
      }

      setOrderCreated(true);
      alert('Pedido creado exitosamente');
    } catch (error) {
      console.error('Error creando el pedido:', error);
      alert('Hubo un error creando el pedido. Ver consola para más detalles.');
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF('p', 'pt', [300, 600]);
    doc.setFontSize(10);
    doc.text('EMPRESA DE DEMOSTRACION', 75, 20);
    doc.text('RFC: YYYY010101YYY', 75, 30);
    doc.text('Regimen fiscal: 601: Ley General de Personas Morales', 75, 40);
    doc.text('Emitido en: Av. Paseo 42-Local H, Col. Centro, Cuauhtemoc, Mexico, CDMX 04321 Tel. 5555456735', 75, 50);
    doc.text(`Cajero: ${client.contactPerson}`, 75, 60);
    doc.text(`T010-01322`, 75, 70);
    doc.text(`Fecha y Hora de Entrega: ${new Date(deliveryDate).toLocaleString()}`, 75, 80);
    doc.text(`Cliente: ${client.businessName}`, 75, 90);

    orderProducts.forEach((product, index) => {
      doc.text(`${product.name} x ${product.quantity}`, 75, 100 + (index * 10));
      doc.text(`$${(product.discountedPrice * product.quantity).toFixed(2)}`, 200, 100 + (index * 10));
    });

    doc.text(`Total de Productos: ${totalQuantity}`, 75, 100 + (orderProducts.length * 10) + 10);
    doc.text(`Total del Pedido: $${totalPrice.toFixed(2)}`, 200, 100 + (orderProducts.length * 10) + 10);

    doc.text('¡GRACIAS POR SU COMPRA!', 75, 100 + (orderProducts.length * 10) + 30);

    doc.save('ticket.pdf');
  };

  const sendWhatsApp = () => {
    const phone = client.phone.replace(/\D/g, '');
    const whatsappURL = `https://wa.me/52${phone}?text=Gracias%20por%20tu%20compra.%20Tu%20total%20es%20$${totalPrice.toFixed(2)}.%20¡Gracias%20por%20su%20compra!`;
    window.open(whatsappURL, '_blank');
  };

  if (loading) {
    return <div className="container mx-auto p-4">Cargando...</div>;
  }

  if (error) {
    return <div className="container mx-auto p-4">{error}</div>;
  }

  if (orderCreated) {
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-2xl mb-4">Pedido Creado Exitosamente</h1>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
          onClick={generatePDF}
        >
          Descargar Comprobante
        </button>
        <button
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600 ml-2"
          onClick={sendWhatsApp}
        >
          Enviar por WhatsApp
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4">Crear Pedido</h1>
      <h2 className="text-xl mb-4">Pedido para: {client.businessName}</h2>

      <div className="mb-4 relative">
        <label className="block mb-2 text-sm font-medium text-gray-700">Producto</label>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Buscar producto"
        />
        {searchTerm && (
          <ul className="absolute z-10 bg-white w-full border border-gray-300 rounded mt-1 max-h-60 overflow-y-auto">
            {filteredProducts.map(product => (
              <li
                key={product.id}
                className="p-2 hover:bg-gray-200 cursor-pointer flex items-center"
                onClick={() => handleProductSelect(product)}
              >
                <img src={product.imageUrl} alt={product.name} className="w-10 h-10 mr-2" />
                {product.name}
              </li>
            ))}
          </ul>
        )}
      </div>

      {selectedProduct && (
        <div className="mb-4">
          <h3 className="text-lg font-medium text-gray-900">Producto Seleccionado: {selectedProduct.name}</h3>
          <label className="block mb-2 text-sm font-medium text-gray-700">Cantidad</label>
          <input
            type="number"
            value={productQuantity}
            onChange={(e) => setProductQuantity(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            min="1"
          />
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600 mt-2"
            onClick={handleAddProduct}
          >
            Añadir Producto
          </button>
        </div>
      )}

      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-700">Zona</label>
        <select
          value={selectedZone}
          onChange={(e) => setSelectedZone(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Selecciona una zona</option>
          {zones.map(zone => (
            <option key={zone.id} value={`${zone.municipio}, ${zone.estado}`}>
              {zone.municipio}, {zone.estado}
            </option>
          ))}
        </select>
      </div>

      <div className="mt-4">
        <h3 className="text-lg font-medium text-gray-900">Productos en el Pedido</h3>
        {orderProducts.length > 0 ? (
          <ul className="mt-2 space-y-2">
            {orderProducts.map((product, index) => (
              <li key={index} className="flex justify-between items-center">
                <div className="flex items-center">
                  <img src={product.imageUrl} alt={product.name} className="w-10 h-10 mr-2" />
                  <span>{product.name} x {product.quantity}</span>
                </div>
                <span>${(product.discountedPrice * product.quantity).toFixed(2)}</span>
              </li>
            ))}
          </ul>
        ) : (
          <p>No se han añadido productos.</p>
        )}
      </div>

      <div className="mt-4">
        <h3 className="text-lg font-medium text-gray-900">Resumen del Pedido</h3>
        <p>Total de Productos: {totalQuantity}</p>
        <p>Total del Pedido: ${totalPrice.toFixed(2)}</p>
      </div>

      <div className="mt-4">
        <label className="block mb-2 text-sm font-medium text-gray-700">Fecha y Hora de Entrega</label>
        <input
          type="datetime-local"
          value={deliveryDate}
          onChange={(e) => setDeliveryDate(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <button
        className="mt-4 bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        onClick={handleCreateOrder}
      >
        Crear Pedido
      </button>
    </div>
  );
};

export default CrearPedido;
