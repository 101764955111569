import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { FaClipboardList, FaBox, FaTruckMoving, FaHome } from 'react-icons/fa';

const getStatusColor = (currentStatus, status) => {
  const statusOrder = ['enviado', 'en proceso', 'en ruta', 'entregado'];
  const currentStatusIndex = statusOrder.indexOf(currentStatus);
  const statusIndex = statusOrder.indexOf(status);
  return statusIndex <= currentStatusIndex ? 'bg-green-500' : 'bg-gray-200';
};

const StatusIcon = ({ icon: Icon, label, isActive }) => {
  return (
    <div className="relative flex flex-col items-center z-10">
      <div className={`rounded-full p-3 ${isActive ? 'bg-green-500' : 'bg-gray-200'} text-white`}>
        <Icon size={24} />
      </div>
      <p className="text-xs mt-1">{label}</p>
    </div>
  );
};

const StatusIndicator = ({ currentStatus }) => {
  const statuses = [
    { key: 'enviado', label: 'Enviado', icon: FaClipboardList },
    { key: 'en proceso', label: 'Procesando', icon: FaBox },
    { key: 'en ruta', label: 'En Tránsito', icon: FaTruckMoving },
    { key: 'entregado', label: 'Entregado', icon: FaHome },
  ];
  const currentStatusIndex = statuses.findIndex(s => s.key === currentStatus);

  return (
    <div className="relative w-full flex justify-between items-center my-4">
      <div className="absolute top-1/2 left-0 right-0 h-0.5 bg-gray-200 z-0 transform -translate-y-2 translate-x-2.5">
        <div className={`absolute top-0 left-0 h-0.5 bg-green-500`} style={{ width: `${((currentStatusIndex + 1) / statuses.length) * 100}%` }}></div>
      </div>
      {statuses.map((status, index) => (
        <StatusIcon
          key={index}
          icon={status.icon}
          label={status.label}
          isActive={getStatusColor(currentStatus, status.key) === 'bg-green-500'}
        />
      ))}
    </div>
  );
};

const PedidoDrawer = ({ isOpen, closeDrawer, order }) => {
  if (!order) return null;

  return (
    <Offcanvas show={isOpen} onHide={closeDrawer} placement="end" style={{ zIndex: 1050 }}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Detalles del Pedido</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <p>ID del Pedido: {order.id}</p>
        <p>Total: ${order.total}</p>
        <StatusIndicator currentStatus={order.status} />
        <div className="mt-4">
          <h5>Productos:</h5>
          <ul>
            {order.products.map((product, index) => (
              <li key={index}>{product.name} - Cantidad: {product.quantity} - Precio: ${product.price}</li>
            ))}
          </ul>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default PedidoDrawer;
